import React from 'react';
import {
  FeatureIcon,
  Link,
  MediaContent,
  StickyContent,
  SubNavigationItem,
} from '~/common/models';
import { Heading } from './components/heading';
import { Navigation } from './components/navigation';
import { PageHero, FeatureContentIcons } from 'common/components';
import { ButtonTypeEnum } from '~/common/components/ui-elements';
import { HeaderBannerMediaContent } from './components/media-content';
import styles from './index.module.scss';
import { StickyBlueRibbon } from './components/sticky-blue-ribbon';

interface HeaderBannerProp {
  contentId: string;
  headerHeight: number;
  legalDisclaimer: string;
  legalDisclaimerFontColor: string;
  legalDisclaimerAlignment: 'auto' | 'right' | 'left' | 'center';
  bannerHyperlink?: Link;
  featureContents?: FeatureIcon[];
  mediaContents: MediaContent[];
  navigationItems: SubNavigationItem[];
  navigationFontColor: string;
  navigationBackgroundColor: string;
  navigationIsSticky: boolean;
  ctaButton: Link;
  ctaButtonStyle: keyof typeof ButtonTypeEnum;
  blueRibbon?: StickyContent;
}

export default (props: HeaderBannerProp) => {
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const [headingContent, setHeadingContent] = React.useState<
    FeatureIcon | undefined
  >(undefined);

  React.useEffect(() => {
    if (!!props.featureContents) {
      setHeadingContent(
        props.featureContents.find((f) => f.contentType === 'Heading')
      );
    }
  }, []);

  const isMobile = () => {
    if (containerRef.current === null) return false;
    return containerRef.current?.clientWidth < 923;
  };

  return (
    <>
      <div id="header-banner" className={styles.headerBannerMain}>
        {props.blueRibbon && <StickyBlueRibbon content={props.blueRibbon} />}
        <PageHero
          style={{
            height: `${props.headerHeight}vh`,
          }}
          contentClass={styles.headerBanner}
          mediaContent={() => (
            <HeaderBannerMediaContent
              legalDisclaimer={props.legalDisclaimer}
              legalDisclaimerColor={props.legalDisclaimerFontColor}
              legalDisclaimerPosition={props.legalDisclaimerAlignment}
              mediaContents={props.mediaContents}
              bannerHyperlink={props.bannerHyperlink}
            />
          )}
        >
          <div ref={containerRef} className={styles.headerBannerContent}>
            <FeatureContentIcons features={props.featureContents || []} />
          </div>
        </PageHero>

        {headingContent && <Heading heading={headingContent} />}
      </div>

      {!!props.navigationItems && props.navigationItems.length > 0 && (
        <Navigation
          items={props.navigationItems}
          navigationSticky={props.navigationIsSticky}
          backgroundColor={props.navigationBackgroundColor}
          color={props.navigationFontColor}
          ctaButton={props.ctaButton}
          ctaButtonStyle={props.ctaButtonStyle}
        />
      )}
    </>
  );
};
